<template>
  <full-screen-loader v-if="orderLoading"></full-screen-loader>
  <el-collapse accordion class="boost__accordion">
    <el-collapse-item class="boost__accordion-item" name="1">
      <template #title>
        <h3 class="boost__accordion-item-title" ref="profiles-tab">
          <i class="fas fa-exclamation-circle boost__accordion-icon-status" v-if="!Object.keys(selectedProfiles).length"></i>
          <i class="far fa-check-circle boost__accordion-icon-status" v-else></i>
          {{getTrans('messages.select_profiles')}}
        </h3>
        <div class="boost__accordion-item-completed-info">
          <ProfilesAvatar
            :profiles="Object.values(selectedProfiles)"
            :showTooltip="false"
            :show-delete="true"
            @delete="removeProfile"
          ></ProfilesAvatar>
        </div>
      </template>
      <profiles-filter-list
        v-if="!orderLoading"
        v-model:selectedProfiles="selectedProfiles"
        :link="isLink"
        list-view="card"
        :scroll="true"
      ></profiles-filter-list>
      <div class="text-center mt-4">
        <base-button type="primary" @click="openTab('plans')">{{getTrans('messages.next')}}</base-button>
      </div>
    </el-collapse-item>
    <el-collapse-item name="2" class="boost__accordion-item">
      <template #title>
        <h3 class="boost__accordion-item-title" ref="plans-tab">
          <i class="fas fa-exclamation-circle boost__accordion-icon-status" v-if="!selectedPlan"></i>
          <i class="far fa-check-circle boost__accordion-icon-status" v-else></i>
          {{getTrans('messages.traffic_package')}}
        </h3>
        <div class="boost__accordion-item-completed-info">
          <h4 class="mb-0">{{selectedPlan?.name}}</h4>
        </div>
      </template>
      <plan-filter-list v-if="!orderLoading" v-model:selectedPlan="selectedPlan" :show-map="false"></plan-filter-list>
      <div class="text-center mt-4">
        <base-button type="primary" @click="openTab('settings')">{{getTrans('messages.next')}}</base-button>
      </div>
    </el-collapse-item>
    <el-collapse-item name="3" class="boost__accordion-item">
      <template #title>
        <h3 class="boost__accordion-item-title" ref="settings-tab">
          <i class="fas fa-cogs boost__accordion-icon-status"></i>
          {{getTrans('messages.order_settings')}}
        </h3>
      </template>
      <div class="boost__accordion-body">
          <div class="order-settings__grid">
            <div>
              <div class="order-settings__title">
                <h3 class="order-settings__title--name">{{getTrans('messages.order_title')}}</h3>
                <base-input
                  :type="'text'"
                  v-model="title"
                  :formClasses="'mb-0'"
                >
                </base-input>
              </div>
              <div class="order-settings__traffic-estimations">
                <h4 class="order-settings__traffic-estimations--name">{{getTrans('messages.viewers_per_token')}}:</h4>
                <h3>{{estimatedOrderTrafficPerToken || '-'}}</h3>
              </div>
              <div class="order-settings__traffic-estimations">
                <h4 class="order-settings__traffic-estimations--name">{{getTrans('messages.estimated_viewers')}}:</h4>
                <h3>{{estimatedTraffic || '-'}}</h3>
              </div>
            </div>
            <div>
              <div class="order-settings__range">
                <h4 class="order-settings__range--title">{{getTrans('messages.price')}}:</h4>
                <base-input
                  v-model="budget"
                  :type="'number'"
                  min="3"
                >
                </base-input>
                <base-slider v-model="budget" class="form-range" :range="{min: 3, max: 1000}" :options="{step: 1, tooltips: [true]}"></base-slider>
              </div>
              <div class="order-settings__range">
                <h4 class="order-settings__range--title">{{getTrans('messages.traffic_speed')}}:</h4>
                <div class="order-settings__range--form-wrapper">
                  <div class="order-settings__range--form-state">
                    <span>{{getTrans('messages.slow')}}</span>
                    <strong>{{speedWarningText}}</strong>
                    <span>{{getTrans('messages.fast')}}</span>
                  </div>
                  <base-slider
                    v-model="speed" class="form-range"
                    :range="{min: 1, max: 100}"
                    :options="{step: 1, tooltips: [trafficSpeedFormat]}"
                    :sliderStyles="speedColor"
                  ></base-slider>
                </div>
              </div>
              <div class="order-settings__range">
                <h4 class="order-settings__range--title">{{getTrans('messages.bid')}} %</h4>
                <base-slider v-model="bidPercent" class="form-range" :range="{min: 50, max: 500}" :options="{step: 1, tooltips: [true]}"></base-slider>
              </div>
            </div>
            <div class="order-settings__auto-renew">
              <base-checkbox v-model="showAutoRenew">{{getTrans('messages.auto_renew_order')}}</base-checkbox>
              <template v-if="showAutoRenew">
                <base-checkbox v-model="renewForever">{{getTrans('messages.renew_forever')}}</base-checkbox>
                <div class="order-settings__auto-renew--details-wrap">
                  <div class="order-settings__auto-renew--total" v-if="!renewForever">
                    {{getTrans('messages.number_of_renewals')}}
                    <base-input
                      v-model="renewals"
                      :type="'number'"
                      :formClasses="'mb-0'"
                      min="1"
                    >
                    </base-input>
                  </div>
                  <el-select class="mr-4" v-model="autorenew" :placeholder="getTrans('messages.renewal_time')">
                    <el-option v-for="(option, index) in renewOptions" :value="index" :key="index" :label="option">{{option}}</el-option>
                  </el-select>
                  <template v-if="order">
                    <div class="d-flex align-items-center" v-if="!renewForever && order?.renewals_left > 0">{{getTrans('messages.renewals_left')}}:&nbsp;&nbsp;<strong>{{order.renewals_left}}</strong></div>
                    <div class="d-flex align-items-center" v-if="renewForever">{{getTrans('messages.renewals')}}:&nbsp;&nbsp;<strong>{{order?.renewals_count}}</strong></div>
                  </template>
                </div>
                <div class="order-settings__auto-renew--alert alert alert-warning">
                  <span v-html="getTrans('messages.autorenew_description')"></span>
                </div>
              </template>
              <div class="clearfix">
                <div class="order-settings__summary">
                  <h3>{{getTrans('messages.order_cost')}}: <span>{{budget}}</span> {{getTrans('messages.tokens')}}</h3>
                  <h3>{{getTrans('messages.balance')}}: <span>{{tokensBalance(user.currentBalance)}}</span> {{getTrans('messages.tokens')}}</h3>
                  <h3 v-if="showAutoRenew && autorenew">{{getTrans('messages.total_cost')}}: <span>{{totalBudget}}</span> {{getTrans('messages.tokens')}}</h3>
                </div>
              </div>
              <div class="order-settings__info-block alert alert-secondary alert-dismissible">
                <div class="order-settings__info-block--inner-wrap">
                  <span class="order-settings__info-block--inner-icon">
                    <img src="/img/icons/common/arrows_round.svg" width="20" />
                  </span>
                   <span class="alert-text">
                    <h4>{{getTrans('messages.traffic_stops')}}</h4>
                    {{getTrans('messages.traffic_stops_sub')}}
                  </span>
                </div>
                <div class="order-settings__info-block--inner-wrap">
                  <span class="order-settings__info-block--inner-icon">
                    <img src="/img/icons/common/shield.svg" width="20" />
                  </span>
                   <span class="alert-text">
                    <h4>{{getTrans('messages.limit_visitors')}}</h4>
                    {{getTrans('messages.limit_visitors_sub')}}
                  </span>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="text-center mt-4">
        <base-button type="primary" @click="saveOrder()" :loading="loading || ordersCountLoading">{{getTrans('messages.save')}}</base-button>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script>

import { mapGetters } from 'vuex';
import {ElCollapse, ElCollapseItem, ElSelect, ElOption} from 'element-plus';
import StringFormatter from "@/mixins/StringFormatter";
import Errors from "@/mixins/Errors";
import BaseSlider from "@/components/BaseSlider";
import ProfilesFilterList from "@/views/Profiles/Components/ProfilesFilterList";
import PlanFilterList from "@/views/Plan/Components/PlanFilterList";
import PlanUtilMixin from '@/mixins/Plan/PlanUtilMixin';
import ProfilesAvatar from "@/views/Orders/Components/ProfilesAvatar";
import OrderErrors from "@/views/Orders/Mixins/OrderErrors";

export default {
  name: "boost-my-cam",
  mixins: [StringFormatter, Errors, OrderErrors, PlanUtilMixin],
  components: {
    ElCollapse,
    ElCollapseItem,
    ElSelect,
    ElOption,
    ProfilesAvatar,
    BaseSlider,
    ProfilesFilterList,
    PlanFilterList,
  },
  props: {
    orderId: {
      type: String,
      default: null
    },
    profiles: {
      type: String,
      default: null
    },
    link: {
      type: Boolean,
      default: false
    },
    packageId: {
      type: [String, Number],
      default: ()=>{
        return null;
      }
    }
  },
  data() {
    return {
      id: null,
      title: '',
      traffic_type: 'pop',
      traffic_speed: 7,
      bidPercent: 100,
      budget: 25,
      autorenew: null,
      renewInterval: 0,
      selectedProfiles: {},
      selectedPlan: null,
      speed: 50,
      showAutoRenew: false,
      renewForever: null,
      renewals: 2,
      trafficSpeedFormat: {
        to: function (value) {
          return Math.round(value) + '%';
        },
        from: function (value) {
          return value;
        }
      },
      orderLoading: true,
      isLink: this.link,
    };
  },
  computed: {
    ...mapGetters('Order', {
      order: 'getData',
      getOrderData: 'getDataByName',
      loading: 'loading',
      loadingByName: 'loadingByName',
      errors: 'getErrors',
      statuses: 'statusesByName',
    }),
    ...mapGetters('User', {user: 'getData',}),
    ...mapGetters('Profile', ['getType']),
    ...mapGetters('Plan', {getPackageById: 'getPackageById'}),
    ordersCount() {
      return this.getOrderData('ordersCount');
    },
    bidPercentAvailable() {
      return this.$store.getters['User/can']('orders-bid-percent');
    },
    ordersCountLoading() {
      return this.loadingByName('ordersCount')
    },
    speedWarningText() {
      if (this.speed > 80) {
        return this.getTrans('messages.faster_than_light');
      } else if (this.speed > 70) {
        return this.getTrans('messages.even_faster');
      } else if (this.speed > 60) {
        return this.getTrans('messages.faster_than_normal');
      } else if (this.speed > 40) {
        return this.getTrans('messages.normal_speed');
      } else {
        return this.getTrans('messages.slow_speed');
      }
    },
    speedColor() {
      const color = 0.2 * this.speed * 10;
      const blue = 256 - color;
      return {'background-color': 'rgb('+color+',0,'+blue+')'};
    },
    renewOptions() {
      return {
        1: this.getTrans('messages.immediately'),
        10: this.getTransChoise('messages.minutes', 10),
        30: this.getTransChoise('messages.minutes', 30),
        60: this.getTransChoise('messages.hour', 1),
        120: this.getTransChoise('messages.hour', 2),
        240: this.getTransChoise('messages.hour', 4),
        1440: this.getTransChoise('messages.hour', 24),
      };
    },
    totalBudget() {
      return this.autorenew && !this.renewForever ? this.budget * this.renewals : null;
    },
    estimatedTraffic() {
      return this.estimatedOrderTrafficPerToken ? Math.round(this.estimatedOrderTrafficPerToken * this.budget) : null;
    },
    estimatedOrderTrafficPerToken() {
      return this.estimatedTrafficPerToken(this.selectedPlan, this.bidPercent);
    },
  },
  methods: {
    validatePrice() {
      if (this.budget < 3) {
        this.budget = 3;
      } else if (this.budget > parseInt(this.getUser.currentBalance)) {
        this.budget = parseFloat(this.getUser.currentBalance);
      }
    },
    openTab(tab) {
      this.$refs[tab + '-tab'].click();
    },
    scrollToElement(element, select) {
      let elementPos = this.$refs[element].offsetTop;
      window.scrollTo({
        top: elementPos,
        left: 0,
        behavior: "smooth",
      });
      if (select) {
        this.$refs[select].$refs.input.focus();
      }
    },
    removeProfile(profile) {
      delete this.selectedProfiles[profile.id];
      // Recreate, for ProfilesFilterList watch function.
      this.selectedProfiles = {...this.selectedProfiles}
    },
    orderStatsRedirect() {
      this.$router.push({name: 'order_stats', params: {orderId: this.order.id}});
    },
    async saveOrder() {
      const order = {
        title: this.title,
        plan_id: this.selectedPlan?.id,
        profiles: Object.keys(this.selectedProfiles),
        budget: this.budget / 10,
        total_budget: this.totalBudget ? this.totalBudget / 10 : null,
        bid_percent: null,
        rate_limit: {actions: this.speed * 10},
        autorenew: this.showAutoRenew ? this.autorenew : null,
      };
      if (this.bidPercentAvailable) {
        order.bid_percent = this.bidPercent;
      }

      let action = 'Order/create';
      if (this.id) {
        order.id = this.id;
        action = 'Order/update';
      }

      await this.$store.dispatch(action, order);
      if (this.errors) {
        this.alertErrors();
      } else {
        if (this.id) {
          if (this.order.status === this.statuses['paused']) {
            this.$swal.fire({
              title: this.getTrans('messages.order_updated'),
              text: this.getTrans('messages.start_order') + '?',
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton: true,
              confirmButtonText: this.getTrans('messages.start_order'),
              cancelButtonText: this.getTrans('messages.leave_paused'),
            }).then(async result => {
              if (result.isConfirmed) {
                this.orderLoading = true;
                await this.$store.dispatch('Order/changeStatus', {
                  id: this.order.id,
                  status: 'active'
                });
                this.orderStatsRedirect();
              } else {
                this.orderRedirect();
              }
            });
          } else {
            this.orderRedirect();
          }
        } else {
          this.orderStatsRedirect();
        }
      }
    },
  },
  async mounted() {
    // Load order for edit.
    if (this.orderId) {
      try {
        await this.$store.dispatch('Order/getById', {id: this.orderId});
        if ([this.statuses['paused'], this.statuses['pending'], this.statuses['completed']].includes(this.order.status)) {
          const selectedProfiles = {};
          this.order.profilesList.data.forEach(item => {
            selectedProfiles[item.id] = item;
            if (this.getType(item) === 'link') {
              this.isLink = true;
            }
          });
          this.selectedProfiles = selectedProfiles;

          this.id = this.order.id;
          this.title = this.order.title;
          this.selectedPlan = this.order.plan.data;
          this.budget = this.order.budget * 10;
          this.bidPercent = this.order.bid_percent;
          this.speed = this.order.rate_limit.actions / 10;
          if (this.order.autorenew) {
            this.renewals = this.order.total_budget / this.order.budget;
            this.autorenew = this.order.autorenew;
            this.showAutoRenew = true;
            this.renewForever = this.order.renewals_left === -1 ? true : false;
          }
        } else {
          this.invalidOrder(this.getTrans('messages.order_invalid_status'));
        }
      } catch (e) {
        this.orderNotFound();
      }
      this.orderLoading = false;
    } else {
      this.$store.dispatch('Order/getOrdersCount').then(() => {
        this.title = this.getTrans('messages.my_order') + (this.ordersCount.total + 1);
      });

      const profiles = typeof this.profiles === 'string' ? JSON.parse(this.profiles) : this.profiles;
      if (profiles instanceof Array && profiles.length) {
        profiles.forEach(profile => {
          this.selectedProfiles[profile.id] = profile;
        })
      }
      this.renewForever = false;
      this.orderLoading = false;

      if (this.packageId) {
        this.selectedPlan = this.getPackageById(this.packageId);
      }
    }

    this.openTab('profiles');
  },
  watch: {
    renewForever(val, oldVal) {
      if (val && oldVal !== null) {
        this.$swal.fire({
          title: this.getTrans('messages.renew_forever_text'),
          icon: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCancelButton: true,
          confirmButtonText: this.getTrans('messages.accept'),
          cancelButtonText: this.getTrans('messages.cancel'),
          confirmButtonColor: '#ff9c19',
        }).then(result => {
          if (!result.isConfirmed) {
            this.renewForever = false;
          }
        });
      }
    }
  },
}
</script>
