<template>
  <div class="slider" :class="[`slider-${type}`]" :disabled="disabled"></div>
</template>
<script>
import noUiSlider from "nouislider";
export default {
  name: "base-slider",
  emits: ["update:modelValue"],
  props: {
    modelValue: [String, Array, Number],
    disabled: {
      type: Boolean,
      default: null,
    },
    start: {
      type: [Number, Array],
      default: 0,
    },
    connect: {
      type: [Boolean, Array],
      default: () => [true, false],
    },
    range: {
      type: Object,
      default: () => {
        return {
          min: 0,
          max: 100,
        };
      },
    },
    type: {
      type: String,
      default: "",
    },
    sliderStyles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    format: {
      type: Object,
      default: () => {
        return {
          to: function (value) {
            return Math.round(value);
          },
          from: function (value) {
            return value;
          }
        };
      },
    },
  },
  data() {
    return {
      slider: null,
    };
  },
  methods: {
    createSlider() {
      noUiSlider.create(this.$el, {
        start: this.modelValue || this.start,
        connect: this.connect,
        range: this.range,
        format: this.format,
        ...this.options,
      });
      const slider = this.$el.noUiSlider;
      slider.on("slide", () => {
        let value = slider.get();
        if (value !== this.modelValue) {
          this.$emit("update:modelValue", value);
        }
      });
    },
    setSliderStyles() {
      const slider = this.$el.querySelector('.noUi-connect');
      for (const property in this.sliderStyles) {
        slider.style[property] = this.sliderStyles[property];
      }
    }
  },
  mounted() {
    this.createSlider();
    this.setSliderStyles();
  },
  watch: {
    sliderStyles() {
      this.setSliderStyles();
    },
    modelValue(newValue) {
      this.$el.noUiSlider.updateOptions({
        start: newValue
      });
    }
  },
};
</script>
<style></style>
